import './AVITIM.css';
import {push} from 'connected-react-router';
import { useDispatch } from 'react-redux';
export const AVITIM = (props) => {
    const dispatch = useDispatch();
    return (
        <div style={{"backgroundColor": "#fff", "zIndex": "4", "opacity": "100%", "borderRadius": "8px"}}>
            <div className="AVITIM">
                <div className = "AVITIM-logo"/>
                <div className="classification">
                    <p>1C</p>
                    <p>Лицензирование</p>
                    <p>Разработка</p>
                    <p>Сопровождение</p>
                </div>
            </div>
            
             {/* props.clientAccess?.avitimClient ?  */}
            <div className="fill-Avitim" onClick={async ()=>{await dispatch(push(`/application/${props.id}/newticket`))}}>
                <div  className="invHoverLogo-avit"/>
            </div> 
            {/* :props.trialInfo?.avitimTrial ?
                <div className="fill-Avitim" onClick={props.modalHandler}>
                    <p className='probe-text'>Продолжить работу<br/> с Avitim?</p>
                </div>
                :
                <div className="fill-Avitim"  onClick={()=>{window.location.assign(`https://asista.avitim.kz/${props.id}/trial/newticket`)}}>
                    <p className='probe-text'>Получить бесплатную<br/> консультацию по 1С</p>
                </div> */}
            
        </div>
    )
}