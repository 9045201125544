import './WebsiteSettingsForm.css';

const WebsiteSettingsForm = (props) => {
    const check = props.checked["userAccess"]["checked"] && props.checked["userAccess"]["option"];
    const createAcc = props.checked["createAccount"];

    return (
        <div className="WebsiteSettingsForm">
            <div className="WebsiteSettingsForm-radios">
                <div className="inputs">
                    <input type="radio" value="returnAccess" name={props.createAccountName}
                           className="websiteRadioButton"
                           onChange={props.createAccount}/> <span
                    className="website-checkboxButton"
                    style={createAcc ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="website-hiddenSelectButton"
                    style={createAcc ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <h1
                        className="WebsiteSettingsForm-radios-text">Создать
                        учетную
                        запись на сервере</h1>
                    <div className="RefreshOrCreateForm-modals">
                        <h1 className="header"><span className="modalIcon" onMouseEnter={props.enterMouseQuestion}
                                                     onMouseLeave={props.leaveMouseQuestion}>?</span></h1>
                        <p className="hiddenModalText"
                           style={props.show ? {"display": "block"} : {"display": "none"}}>ID присваевается
                            оборудованию,
                            <br/> а не сотруднику</p>
                    </div>
                </div>
                {createAcc ?
                    <>
                        <div className="inputs">
                            <input type="radio" value="returnAccess" name={props.userAccessName}
                                   className="websiteRadioButton"
                                   onChange={props.userAccessChange}/> <span
                            className="website-checkboxButton"
                            style={check === "Присоединить к домену" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                            className="website-hiddenSelectButton"
                            style={check === "Присоединить к домену" ? {"display": "block"} : {"display": "none"}}> </span></span>
                            <h1 className="WebsiteSettingsForm-radios-text">Присоединить к домену</h1>
                        </div>
                        <div className="inputs">
                            <input type="radio" value="returnAccess" name={props.userAccessName}
                                   className="websiteRadioButton"
                                   onChange={props.userAccessChange}/> <span
                            className="website-checkboxButton"
                            style={check === "Внешний доступ к серверу" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                            className="website-hiddenSelectButton"
                            style={check === "Внешний доступ к серверу" ? {"display": "block"} : {"display": "none"}}> </span></span>
                            <h1 className="WebsiteSettingsForm-radios-text">Внешний доступ к серверу</h1>
                        </div>
                        {check === "Внешний доступ к серверу" ?
                            <div>
                                <h1 className="files-header">Доступ к общим папкам</h1>
                                <div className="directories">
                                    <div>
                                        <input type="text" placeholder="Название папки" className="mutualFiles" name = "dirName" onChange={props.dirNameChange}/>
                                        <select className="classificationSelect" onBlur={props.dirSave} name = "classSelect" onChange={props.dirClassChange}>
                                            <option hidden={true}>Выберите опцию</option>
                                            <option>Редактор</option>
                                            <option>Просмотр</option>
                                        </select>
                                        <div className="WebsiteSettingsForm-hiddenOptionBackground">
                                        </div>
                                    </div>
                                </div>
                                <div className="directories">
                                    {props.directories}
                                </div>
                                <a className="addDirectoryBtn" onClick={props.add}>+ добавить еще папку</a>
                            </div> : null }
                    </>
                    : null}
            </div>
        </div>
    )

}

export default WebsiteSettingsForm;