import './LogoSidebar.css';

export const LogoSidebar = (props) => {
    return (
        <div className="LogoSideBar">
            <div className="sidebarColor"></div>
            <div className="avitim"></div>
            <div className="logo"></div>
        </div>
    )
}