import './CHeaderForm.css';

export const CHeaderForm = (props) => {
    return (
        <div className="CHeaderForm">
            <div className="HeaderForm-icons">
                <div></div>
                <div className="vcc-logo"></div>
                <div className="invision-logo"></div>
                <div className="avitim-logo"></div>
            </div>
            <div className="HeaderForm-profile">
                <p className="text">Главная</p>
                <p className="text">Все трансляции</p>
                <div className="info" onClick={props.showProfile} >
                    <div className="icon"><p>A</p></div>
                    <p className="text">a.khalov</p>
                </div>
            </div>
        </div>
    )
}