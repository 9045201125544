import './CommentForm.css';

const CommentForm = (props) => {
    return (
        <div className="CommentForm">
            <textarea className="comment" style={{"outline": "none"}} cols="40" rows="5" placeholder="Дополнительный комментарий"
                      name={props.commentName} onChange={props.commentChange}/>
        </div>

    )
}

export default CommentForm;