import React from 'react';
import './HelperInfo.css';

const HelperInfo = (props) => {
    return (
        <div className="HelperInfo">
            <p style={props.grey ? {color: "#BDBDBD"} : null} className="HelperInfo__text">{props.question}</p>
            <a className="HelperInfo__link" href={props.link}>{props.textLink}</a>
            <p style={props.grey ? {color: "#BDBDBD"} : null} className="HelperInfo__text">{props.contacts}</p>

        </div>
    )
}

export default HelperInfo;
