import './NewUserDataForm.css';

const NewUserDataForm = (props) => {


    return (
        <div className="NewUserDataForm">
            <div className="NewUserDataForm-header-second">
                <h1 className="header">
                    Данные нового пользователя
                </h1>
            </div>
            <div className="NewUserDataForm-inputs">
                <input type="text" placeholder="ФИО нового пользователя" name = {props.NameSurnameMidname} onChange={props.MSNChange}/>
                <input type="text" placeholder="Контактный телефон" name = {props.phoneName} onChange={props.phoneChange}/>
                <input type="text" placeholder="Должность" name = {props.rankName} onChange={props.rankChange}/>
            </div>

            <div className="NewUserDataForm-footer">
                <input type="text" placeholder="Дата выхода на работу ДД/ММ/ГГ" disabled={props.checked["working"]} name = {props.dateOfStartName} onChange={props.dateOfStartChange} className="NewUserDataForm-dateOfStart"/>
                <div className="NewUserDataForm-radio">
                    <input type="radio" name={props.radioName} className="NewUser-workingCurrent"
                           onChange={props.working} onClick={props.visible}/> <span
                    className="workingCurrent-radioButton"
                    style={props.checked["working"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="hiddenSelectButton"
                    style={props.checked["working"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="workingCurrent-text">Уже работает</p>
                </div>
            </div>

        </div>
    )
}

export default NewUserDataForm;