import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import EmailSettingsForm from "../../Components/OpenAccessPageComponent/EmailSettingsForm/EmailSettingsForm";
import './OpenAccessPage.css';
import RefreshOrCreateForm from "../../Components/OpenAccessPageComponent/RefreshOrCreateIdForm/RefreshOrCreateForm";
import NewUserDataForm from "../../Components/OpenAccessPageComponent/NewUserDataForm/NewUserDataForm";
import WebsiteSettingsForm from "../../Components/OpenAccessPageComponent/WebsiteSettingsForm/WebsiteSettingsForm";
import Access1CForm from "../../Components/OpenAccessPageComponent/1CAccessForm/Access1CForm";
import InternalNumberForm from "../../Components/OpenAccessPageComponent/InternalNumberForm/InternalNumberForm";
import ProgramFilesForm from "../../Components/OpenAccessPageComponent/ProgramFilesForm/ProgramFilesForm";
import CommentForm from "../../Components/OpenAccessPageComponent/CommentForm/CommentForm";
import BlueButton from "../../Components/ApplicationPageComponents/BlueButton/BlueButton";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {openAccessData} from "../../Components/OpenAccessPageComponent/data";
import LayoutApplicationPage
    from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import {push} from "connected-react-router";
import PreviousApplicationMenu
    from "../../Components/ApplicationPageComponents/PreviousApplicationMenu/PreviousApplicationMenu";
import {
    clearMyInterval,
    getClientName, getLastApplication,
    setApplicationBackInProgress
} from "../../Store/ApplicationsReducer/applicationsActions";
import EmployeeMenu from "../../Components/OpenAccessPageComponent/EmployeeMenu/EmployeeMenu";

const OpenAccessPage = (props) => {
    const lastApplication = useSelector(state => state.applications.lastApplication);
    let leftSide;
    const id = props.match.params.id;
    const dispatch = useDispatch();
    const client = useSelector(state => state.applications.clientName)

    useEffect(() => {
        dispatch(getLastApplication(id));
        dispatch(getClientName(id))
        if (!client.result) {
            dispatch(push("/error"))
        }
    }, [dispatch, id])

    const [showModal, setShowModal] = useState(false);
    const [showSelectOptions, setShowSelectOptions] = useState("");
    const [placeholderState] = useState("");
    let [directoryData] = useState([]);
    let [directoryState, setDirectoryState] = useState({
    });

    const [showModalQuestion, setShowModalQuestion] = useState(false);
    const [showMCApps, setShowMCApps] = useState(false);
    const [disabled, setDisabled] = useState(true);
    let [keyCount, setKeyCount] = useState(2);
    const [appsState] = useState({
        "adobeAcrobat": false,
        "winRAR": false,
        "skype": false,
        "chrome": false
    })
    const [reportingState] = useState({
        "NCALayer": false,
        "COHO": false,
        "cabinetSalyk": false
    })
    const [microsoftAppsState] = useState({
        "word": false,
        "powerpoint": false,
        "onenote": false,
        "visio": false,
        "project": false,
        "access": false,
        "publisher": false,
        "outlook": false,
        "excel": false
    })
    const [inputState, setInputState] = useState(openAccessData);
    const [newUserState, setNewUserState] = useState(false);
    const [directories] = useState([]);
    const submitHandler = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        Object.keys(inputState).forEach(key => {
            if (typeof inputState[key] === 'object' && inputState[key] !== null) {
                for (let i = 0; i < inputState[key].length; i++) {
                    formData.append(key + "[]", inputState[key][i], inputState[key][i].name);
                }
            } else {
                formData.append(key, inputState[key]);
            }
        })
        console.log(inputState);

        dispatch(push("/access/open/status"));
        // dispatch(sendEmail(data));

        // console.log(inputState, id);
        // dispatch(postNewRequest(formData, id));
        // dispatch(postNewApplication(formData, id));

        // clearInputState();
    }
    const backToMain = () => {
        dispatch(push(`/${id}/access/new`));
    }

    const showModalText = () => {
        setShowModal(true);
    }
    const hideModalText = () => {
        setShowModal(false);
    }

    const showModalQuestionHandler = () => {
        setShowModalQuestion(true);
    }

    const hideModalQuestionHandler = () => {
        setShowModalQuestion(false);
    }

    const showOptionsModal = (e) => {
        setShowSelectOptions(e.target.name);
        if (e.target.name === "version") {
            setShowMCApps(true);
        }
    }
    const hideOptionsModal = () => {
        setShowSelectOptions("");
    }
    const buttonAddHandler = () => {
        directories.push(<div key={keyCount}>
            <input type="text" placeholder="Название папки" className="mutualFiles" name="dirName"
                   onChange={(e) => directoryFileNameHandler(e)}/>
            <select className="classificationSelect" name="classSelect" onBlur={directoryHandler} onChange={(e) => directoryClassificationHandler(e)}>
                <option hidden={true}>Выберите опцию</option>
                <option>Редактор</option>
                <option>Просмотр</option>
            </select>
        </div>);

        setKeyCount(++keyCount);
    }
    const directoryHandler = () => {
        directoryData.push(directoryState);
        setDirectoryState({});
        setInputState(prevState => {
            return {...prevState, mutualFiles: directoryData}
        })
    }

    const directoryFileNameHandler = (e) => {
        const {value, name} = e.target;
        directoryState[name] = value;
    }

    const directoryClassificationHandler = (e) => {
        const {value, name} = e.target;
        directoryState[name] = value;
    }

    const isBackInProgressHandler = () => {
        dispatch(setApplicationBackInProgress(lastApplication.ref));
        dispatch(push(`/application/${id}/${lastApplication.ref}`))
    }

    if (lastApplication && !!lastApplication.result) {
        leftSide = (
            <>
                <PreviousApplicationMenu
                    title= "Предыдущая заявка"
                    name={lastApplication.topic}
                    date={lastApplication.date}
                    description=""
                    buttonName="Вернуть в работу"
                    clicked={isBackInProgressHandler}
                />
                <EmployeeMenu
                    title= "Прием или увольнение сотрудника"
                    description=""
                    buttonName="Отправить заявку"
                    // clicked={isBackInProgressHandler}
                />
            </>
        )
    }

    const selectHandler = (event) => {
        const {name, value} = event.target;

        setInputState(prevState => {
            return {...prevState, [name]: value}
        });
        if (value === "Старый пользователь") {
            setNewUserState(false);
        } else if (value === "Новый пользователь") {
            setNewUserState(true);
        }
    }

    const checkedHandler = (event) => {
        const name = event.target.name;
        setInputState(prevState => {
            return {...prevState, [name]: event.target.checked}
        })
    }

    const multipleCheckedHandler = (event) => {
        let child = event.target.nextSibling.nextSibling.textContent;
        if (child === " " || child === undefined) {
            child = event.target.nextSibling.nextSibling.nextSibling.textContent;
        }
        const name = event.target.name;
        console.log(child, name);
        setInputState(prevState => {
            return {
                ...prevState, [name]: {
                    "option": child,
                    "checked": event.target.checked
                }
            }
        })
    }

    const inputHandler = (event) => {
        const {name, value} = event.target;
        setInputState(prevState => {
            return {...prevState, [name]: value}
        });
    }

    const emailChecker = (event) => {
        const {name, value} = event.target;
        const symbols = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (symbols.test(value)) {
            setInputState(prevState => {
                return {...prevState, [name]: value}
            });
            if (inputState["email"]) {
                setDisabled(false);
            }
        } else {
            setDisabled(true);
        }
    }


    const checkboxHandler = (event) => {
        const name = event.target.name;
        let attributeName = event.target;
        if (attributeName.previousSibling) {
            attributeName = event.target.previousSibling.title;
            if (name === "popularApps") {
                appsState[attributeName] = event.target.checked;
                setInputState(prevState => {
                    return {
                        ...prevState, [name]: appsState
                    }
                })
            } else {
                microsoftAppsState[attributeName] = event.target.checked;
                setInputState(prevState => {
                    return {
                        ...prevState, [name]: microsoftAppsState
                    }
                })
                console.log(microsoftAppsState);
            }
        } else if (attributeName.nextSibling) {
            attributeName = event.target.nextSibling.nextSibling.nextSibling.title;
            reportingState[attributeName] = event.target.checked;
            setInputState(prevState => {
                return {
                    ...prevState, [name]: reportingState
                }
            })
        }
    }

    const center = (
        <div className="OpenAccessPage">
            <form className="OpenAccessPage-inputs-background" onSubmit={(e) => submitHandler(e)}>
                <div className="OpenAccessPage-header">
                    <h2 onClick={backToMain}><i className="leftArrow"/> Назад</h2>
                    <h1>Запрос на открытие доступов</h1>
                </div>
                <RefreshOrCreateForm CORSelectInstrName="instrument" CORSelectUsersName="user"
                                     CORSelectChange={(event) => selectHandler(event)} isOldUser={newUserState}
                                     leaveMouse={(e) => hideOptionsModal(e)} enterMouse={(e) => showOptionsModal(e)}
                                     previousEmployee={(e) => inputHandler(e)}
                                     prevEmployeeName="prevEmployee"
                                     enterMouseQuestion={showModalQuestionHandler}
                                     leaveMouseQuestion={hideModalQuestionHandler}
                                     show={showModalQuestion}
                                     isSelected={showSelectOptions}
                                     placeholderSelect={placeholderState}
                                     CORSelectLocationOldUserName="locationOldUser"
                                     returnAccessName="returnAccessOldUser"
                                     returnAccessChange={(e) => checkedHandler(e)}
                                     checked={inputState}> </RefreshOrCreateForm>
                {newUserState ?
                    <NewUserDataForm radioName="working" NameSurnameMidname="MSN" MSNChange={(e) => inputHandler(e)}
                                     phoneName="phone" phoneChange={(e) => inputHandler(e)} rankName="rank"
                                     rankChange={(e) => inputHandler(e)}
                                     dateOfStartName="dateOfStart" dateOfStartChange={(e) => inputHandler(e)}
                                     working={(e) => checkedHandler(e)}
                                     checked={inputState}> </NewUserDataForm> : null}
                <EmailSettingsForm emailChange={(e) => emailChecker(e)} emailName="email"
                                   spamEmailChange={(e) => emailChecker(e)}
                                   spamEmailName="spamEmail" passwordModifier={(e) => multipleCheckedHandler(e)}
                                   passwordModifierName="passModifier" outlookSetName="outlookSetting"
                                   outlookSetChange={(e) => checkedHandler(e)}
                                   checked={inputState} isWrong = {disabled}> </EmailSettingsForm>
                <WebsiteSettingsForm userAccessName="userAccess"
                                     createAccountName="createAccount"
                                     createAccount={(e) => checkedHandler(e)}
                                     userAccessChange={(e) => multipleCheckedHandler(e)} add={buttonAddHandler}
                                     directories={directories} checked={inputState}
                                     dirNameChange={(e) => directoryFileNameHandler(e)} dirClassChange = {(e) => directoryClassificationHandler(e)} dirSave = {directoryHandler}> </WebsiteSettingsForm>
                <Access1CForm accessName="access1C" accessChange={(e) => multipleCheckedHandler(e)}
                              locationName="location1C" locationChange={(e) => inputHandler(e)}
                              checked={inputState}> </Access1CForm>
                <InternalNumberForm deviceModifierName="deviceModifier" internalNumberName="internalNumber"
                                    deviceModifierChange={(e) => multipleCheckedHandler(e)}
                                    internalNumberChange={(e) => inputHandler(e)}
                                    checked={inputState} deviceName="deviceName"
                                    deviceChange={(e) => inputHandler(e)}> </InternalNumberForm>
                <ProgramFilesForm versionName="version"
                                  versionChange={(event) => selectHandler(event)} popAppsName="popularApps"
                                  checkboxChange={(e) => checkboxHandler(e)} antivirusName="antivirus"
                                  antivirusChange={(event) => selectHandler(event)} bankProgramName="bankProgram"
                                  bankProgramChange={(e) => inputHandler(e)} moreProgramName="moreProgram"
                                  moreProgramChange={(e) => inputHandler(e)} reportingsName="reportings"
                                  showModal={showModal}
                                  checked={inputState}
                                  enterMouse={showModalText} leaveMouse={hideModalText}
                                  leaveMouseSelect={(e) => hideOptionsModal(e)}
                                  enterMouseSelect={(e) => showOptionsModal(e)}
                                  isSelected={showSelectOptions}
                                  reportState={reportingState} appsState={appsState}
                                  microsoftState={microsoftAppsState}
                                  microsoftName="microsoft"
                                  showMC={showMCApps}> </ProgramFilesForm>
                <CommentForm commentName="comment" commentChange={(e) => inputHandler(e)}> </CommentForm>
                <BlueButton isDisabled={disabled} name="Отправить заявку"> </BlueButton>
            </form>
        </div>
    )

    const left = (
        <>
            {leftSide}
        </>
    )

    return (
        <div>
            <LogoSidebar></LogoSidebar>
            <LayoutApplicationPage left={left} center={center}> </LayoutApplicationPage>
        </div>
    )
}

export default WithLoader(OpenAccessPage, axios);