import './INVISION.css';

export const INVISION = (props) => {
    return (
        <div style={props.clientAccess?.invisionClient ? {"backgroundColor": "#fff", "borderRadius": "8px"} : {"backgroundColor": "#e0e0e0", "borderRadius": "8px"}}>
            <div className="INVISION">
                <div className = "INVISION-logo"/>
                <div className="classification">
                    <p>Сайты</p>
                    <p>Хостинг</p>
                    <p>Домены</p>
                    <p>e-commerce</p>
                </div>
            </div>
            {props.clientAccess?.invisionClient ? 
                <div className="fill-inv">
                    <div className="invHoverLogo-inv"/>
                </div>
                :
                props.trialInfo?.invisionTrial ?
                <div className="fill-inv" onClick={props.modalHandler}>
                    <p className='probe-text'>Консультации закончились <br/>Продолжить работу?</p>
                </div>
                :
                <div className="fill-inv">
                    <p onClick={()=>{window.location.assign(`https://test.asista.kz/${props.id}/trialinvision/newticket?fromCompany=avitim`)}} className='probe-text'>Получить бесплатную<br/> консультацию по WEB</p>
                </div>
                
                
            }
        </div>
    )
}