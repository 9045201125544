import {
    CHANGE_DEPARTMENT,
    CHANGE_EMPLOYEE,
    CHANGE_NUMBER,
    CHANGE_PAGINATION, CHANGE_REGION,
    CHANGE_STATUS, CLEAR_INTERVAL, FIRST_CALENDAR_DAY_IN_RANGE, FORGET_ME, GET_CLIENT_NAME,
    GET_COUNT_AMOUNT, GET_CURRENT_APPLICATION_DATA, GET_HASH_OF_THE_LAST_APPLICATION, GET_LAST_APPLICATION,
    GET_TEN_APPLICATIONS, INIT_APPLICATION_STATE,
    INIT_FILTERS,
    INPUT_FILTER_DATE_FROM,
    INPUT_FILTER_DATE_TO,
    IS_FILTER_DATE_ACTIVE,
    SET_CONTINUEWORK,
    SAVE_HASH,
    SET_USER_ITSUPPORT_ID,
    SET_TRIAL_INFO,
    SAVE_ID, SECOND_CALENDAR_DAY_IN_RANGE,
    SET_ACCESS_CLIENT,
    SET_CLIENT_PHONE,
    SET_ACTIVE_FILTERS,
    SET_ACTIVE_PAGE, SET_INTERVAL
} from "./applicationsActionTypes";
import axios from "../../axiosApi";
import axiosOriginal from 'axios';
import {push} from "connected-react-router";
import {CONFIG} from "../../constants/backendUrl";

export const getTenApplicationsSuccess = (value) => ({type: GET_TEN_APPLICATIONS, value});
export const getCountAmount = (value) => ({type: GET_COUNT_AMOUNT, value});
export const inputFilterDateFrom = (value) => ({type: INPUT_FILTER_DATE_FROM, value});
export const inputFilterDateTo = (value) => ({type: INPUT_FILTER_DATE_TO, value});
export const setActiveFilters = (value) => ({type: SET_ACTIVE_FILTERS, value});

export const changePagination = (value) => ({type: CHANGE_PAGINATION, value});

export const changeStatus = (value) => ({type: CHANGE_STATUS, value});
export const changeDepartment = (value) => ({type: CHANGE_DEPARTMENT, value});
export const changeEmployee = (value) => ({type: CHANGE_EMPLOYEE, value});
export const changeRegion = (value) => ({type: CHANGE_REGION, value});
export const changeNumber = (value) => ({type: CHANGE_NUMBER, value});
export const initFilters = () => ({type: INIT_FILTERS});

export const setActivePage = (value) => ({type: SET_ACTIVE_PAGE, value});

export const saveHash = (value) => ({type: SAVE_HASH, value});
export const saveId = (value) => ({type: SAVE_ID, value});

export const isFilterDateActive = (value) => ({type: IS_FILTER_DATE_ACTIVE, value});

export const setFirstCalendarDay = (value) => ({type: FIRST_CALENDAR_DAY_IN_RANGE, value});
export const setSecondCalendarDay = (value) => ({type: SECOND_CALENDAR_DAY_IN_RANGE, value});

export const getHashOfTheLastApplication = (value) => ({type: GET_HASH_OF_THE_LAST_APPLICATION, value});

export const getCurrentApplicationDataSuccess = (value) => ({type: GET_CURRENT_APPLICATION_DATA, value});
export const setTrialInfo = (value) =>({type: SET_TRIAL_INFO, value})
export const setMyInterval = (value) => ({type: SET_INTERVAL, value});
export const clearMyInterval = () => ({type: CLEAR_INTERVAL});

export const getClientNameSuccess = (value) => ({type: GET_CLIENT_NAME, value});
export const initApplicationState = () => ({type: INIT_APPLICATION_STATE})
export const setClientAccess = (value) => ({type: SET_ACCESS_CLIENT, value})
export const forgetMe = () => ({type: FORGET_ME})
export const setUserITsupportID = (value)=>({type: SET_USER_ITSUPPORT_ID, value})
export const setContinueWork = (value)=>({type: SET_CONTINUEWORK, value})
export const setClientPhone = (value) => ({type: SET_CLIENT_PHONE, value})
    export const getTenApplications = (data) => {
    return async dispatch => {
        try {
            await axios.post(`/${CONFIG['historyApi']}/Events/method/history`, data)
                .then(response => {
                    dispatch(getTenApplicationsSuccess(response.data.events));
                    dispatch(getCountAmount(response.data.count));
                });
        } catch (err) {
            console.log(err);
        }
    }
}
export const getLastApplicationSuccess = (value) => ({type: GET_LAST_APPLICATION, value});

export const getLastApplication = (id) => {
    return async dispatch => {
        try {
            const response = await axios.get(`/${CONFIG['historyApi']}/event/method/lastevent/?id=${id}`);
            dispatch(getLastApplicationSuccess(response.data));
        } catch (err) {
            console.log(err);
            dispatch(push("/error"))
        }
    }
}

export const postNewApplication = (data, id, trial) => {
    return async dispatch => {
        try {
            if(trial === false){
                const response = await axiosOriginal.post(`${CONFIG['mainProxyApi']}/proxy.php?act=createEvent`, data);
                console.log(response);
                dispatch(getHashOfTheLastApplication(response.data.eventID))
                dispatch(push(`/application/${id}/${response.data.eventID}`));
            }else{
                const responseTrial = await axios.post(`/trial/avitim`, data);
                dispatch(getHashOfTheLastApplication(responseTrial.data.eventID))
                dispatch(push(`/${id}/trial/${responseTrial.data.eventID}`))
            }
            
        } catch(err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}
export const postNewTrialApplication = (data, id, company)=>{
    return async dispatch => {
        try {
            if(company==="itsupport"){
            const response = await axios.post(`/trial/itsupport`, data);
            dispatch(getHashOfTheLastApplication(response.data.eventID))
            dispatch(push(`/${id}/trial/${response.data.eventID}`))
            }else {
                const response = await axios.post(`/trial/avitim`, data);
                dispatch(getHashOfTheLastApplication(response.data.eventID))
                dispatch(push(`/${id}/trial/${response.data.eventID}`))
            }
            
        } catch(err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}
export const getCurrentApplicationData = (id) => {
    return async dispatch => {
        try {
            const response = await axios.get(`/${CONFIG['historyApi']}/equeue/?document=${id}`);
            console.log(id);
            console.log(response);
            await dispatch(getCurrentApplicationDataSuccess(response.data));
        } catch(err) {
            dispatch(push('/error'))
            console.log(err)
        }
    }
}

export const getCurrentTrialApplicationData = (id) => {
    return async dispatch => {
       
            const response = await axios.get(`/${CONFIG['historyApi']}/equeue/?document=${id}`);
            console.log(response.data);
            await dispatch(getCurrentApplicationDataSuccess(response.data));
      
    }
}

export const addDetailsToApplicationInProcess = (data) => {
    return async () => {
        try {
            await axios.post(`/${CONFIG['historyApi']}/event/method/eventupdatebody/`, data)
        } catch(err) {
            console.log(err)
        }
    }
}


export const setApplicationBackInProgress = (id) => {
    return async (dispatch) => {
        try {
            await axios.get(`/${CONFIG['historyApi']}/event/method/eventupdate?document=${id}`);
        } catch (err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}

export const leaveTheRate = (data) => {
    return async () => {
        try {
            const response = axios.get(`${CONFIG["mainProxyApi"]}/proxy.php?act=updateEventRate&document=${data.document}&rate=${data.rate}`)
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }
}

export const addTheCommentToSpecialist = (data) => {
    return async () => {
        try {
            const response=await axiosOriginal.post(`${CONFIG['mainProxyApi']}/proxy.php?act=updateEventComment`, data)
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }
}

export const getClientName = (id) => {
    return async dispatch => {
        try {
            const response = await axios.get(`/${CONFIG['historyApi']}/clientinfo/name/?ID=${id}`);
            await dispatch(getClientNameSuccess(response.data))
        } catch (err) {
            console.log(err);
            return dispatch(push("/error"))

        }
    }
}

export const getClientInfo = (id) =>{
    return async dispatch =>{
        try{
            const response = await axios.get(`/${CONFIG['usersWebApi']}/${id}`)
            // if(response.data.phone===undefined || response.data.phone===null || response.data.error.length>0){
            //     response= await axios.get(`/users/${id}`)
            // }
            dispatch(setClientPhone(response.data.phone))
            
        }catch(e){
            dispatch(push('/error'))
            console.log(e);
        }
    }
}

export const getClientTrialInfo = (id) =>{
    return async dispatch =>{
        try{
            const response = await axios.get(`/users/${id}`)

            dispatch(setClientPhone(response.data.phone))
            
        }catch(e){
            dispatch(push('/error'))
            console.log(e);
        }
    }
}
export const getClientNameTrial = (id) =>{
    return async dispatch => {
        try {
            const response = await axios.get(`/CRM/hs/clientinfo/name/?ID=${id}`)
            await dispatch(getClientNameSuccess(response.data))
        }catch(e){
            dispatch(push('/error'))
        }
    }
}

export const getClientCompaniesAccess = (tel) => {
    return async dispatch => {
        
        const response = await axios.get(`/prelogin/${tel}`);
        
        const bodyData = response.data;
        console.log(`Телефон: ${tel}`);
        console.log(response.data);
        await dispatch(setClientAccess(bodyData))
    }
}

export const getTrialInfo = (tel) =>{
    return async dispatch => {
        
        const reqBody = {
            phone: tel,
            company: "avitim"
        }
        const response = await axios.post(`/trial/get`, reqBody);
        
        const bodyData = {
            avitimTrial: response.data.avitim,
            invisionTrial: response.data.invision,
            itsupportTrial: response.data.itsupport
        }
        const continueWorkData = {
            avitim: response.data.continueWorkReq.avitim,
            invision: response.data.continueWorkReq.invision,
            itsupport: response.data.continueWorkReq.itsupport,
        }

        await dispatch(setTrialInfo(bodyData))
        await dispatch(setContinueWork(continueWorkData))
    }
}

export const postTrialInfo = (data) =>{
    return async dispatch => {
        const response = await axios.post(`/trial/update`, data);
        console.log(response);
    }
}

export const setUserItsupportID = (tel) =>{
    return async dispatch =>{
        try{
            if(tel[0]==="+"){
                tel = tel.substring(1);
            }
            const response = await axios.get(`/trial/get/device_id/itsupport/${tel}`)
            await dispatch(setUserITsupportID(response.data.device_id))
        }catch(e){
            console.log(e);
        }
        
    }
}

export const postToContinueWork = (data)=>{
    return async dispatch => {
        try {
                const responseTrial = await axios.post(`/resume_work/company`, data);
                console.log(responseTrial);
        } catch(err) {
            dispatch(push('/error'))
            console.log(err);
        }
    }
}
