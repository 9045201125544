import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import LayoutApplicationPage from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import {LogoSidebar} from "../../constants/UI/LogoSidebar";
import {PPHeaderForm} from "../../Components/ConferencePageComponents/PersonalProfilePage/HeaderForm/PPHeaderForm";
import './PersonalProfilePage.css';
import {TranslationsForm} from "../../Components/ConferencePageComponents/PersonalProfilePage/TranslationsForm/TranslationsForm";
import {FooterInfo} from "../../constants/Contact/FooterInfo/FooterInfo";
import React, {useEffect} from "react";
import {ITSGContact} from "../../constants/Contact/ITSGContact/ITSGContact";
import {clearMyInterval} from "../../Store/ApplicationsReducer/applicationsActions";
import {push} from "connected-react-router";
import {useDispatch, useSelector} from "react-redux";

const PersonalProfilePage = (props) => {

    const dispatch = useDispatch();
    const hash = useSelector(state => state.applications.data.hash)
    const clientId = useSelector(state => state.applications.data.clientId)


    useEffect(() => {
        dispatch(clearMyInterval())
        if (clientId && !hash) return dispatch(push(`/login/${clientId}`))
        if (!hash) return dispatch(push('/login'));
    }, [dispatch]);


    const body = (
        <div className="PersonalProfile">
            <PPHeaderForm/>
            <TranslationsForm/>
        </div>
    )

    const left = (
        <div>
            <LogoSidebar />
        </div>
    )

    const bottom = (
        <div className="footer">
            <FooterInfo/>
            <ITSGContact/>
        </div>
    )


    return (
        <LayoutApplicationPage left = {left} center={body} bottom = {bottom} />
    )
}

export default WithLoader(PersonalProfilePage, axios);