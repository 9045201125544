import './InternalNumberForm.css';

const InternalNumberForm = (props) => {

    return (
        <div className="InternalNumberForm">
            <h1 className="header">
                Создать внутренний номер
            </h1>
            <input type="text" className="InternalNumberForm-phone" name={props.internalNumberName}
                   onChange={props.internalNumberChange} placeholder="123"/>
            <div className="InternalNumberForm-radios">
                <div className="radios">
                    <input type="radio" name={props.deviceModifierName} className="internalNumberRadio"
                           onChange={props.deviceModifierChange}/> <span
                    className="internalNumber-radioButton"
                    style={props.checked["deviceModifier"]["checked"] && props.checked["deviceModifier"]["option"] === "Установить новый телефонный аппарат" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="internalNumber-hiddenSelectButton"
                    style={props.checked["deviceModifier"]["checked"] && props.checked["deviceModifier"]["option"] === "Установить новый телефонный аппарат" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="InternalNumberForm-radios-text" title="option">Установить новый телефонный аппарат</p>
                </div>
                <div className="radios">
                    <input type="radio" name={props.deviceModifierName} className="internalNumberRadio"
                           onChange={props.deviceModifierChange}/> <span
                    className="internalNumber-radioButton"
                    style={props.checked["deviceModifier"]["checked"] && props.checked["deviceModifier"]["option"] === "Подключить принтер или другое внешнее устройство" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="internalNumber-hiddenSelectButton"
                    style={props.checked["deviceModifier"]["checked"] && props.checked["deviceModifier"]["option"] === "Подключить принтер или другое внешнее устройство" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="InternalNumberForm-radios-text" title="option">Подключить принтер или другое внешнее
                        устройство</p>
                </div>
            </div>
            <input type="text" className="InternalNumberForm-hiddenPrinterName" name={props.deviceName}
                   onChange={props.deviceChange}
                   style={props.checked["deviceModifier"]["checked"] && props.checked["deviceModifier"]["option"] === "Подключить принтер или другое внешнее устройство" ? {"display": "block"} : {"display": "none"}}
                   placeholder="Указать модель принтера или МФУ"/>
        </div>
    )
}

export default InternalNumberForm;