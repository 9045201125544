import React, {useEffect, useState} from 'react';
import BlueButton from '../BlueButton/BlueButton';
import './SpecialitsWindowStatus.css';
import {useDispatch} from "react-redux";
import {addTheCommentToSpecialist, leaveTheRate} from "../../../Store/ApplicationsReducer/applicationsActions";
import ReviewPopUp from '../ReviewPopUp/ReviewPopUp';
import {ReactComponent as CommentIcon} from "../../../assets/icons/comment_icon.svg";
import WhiteButton from '../WhiteButton/WhiteButton';
const SpecialitsWindowStatus = (props) => {
    let timerDuration = props.timerDuration; //15 минут
    const dispatch = useDispatch();
    let topComponent;
    const [seconds, setSeconds] = useState('00');
    const [minutes, setMinutes] = useState(15);
    const [, setIsComment] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [isDisLike, setIsDisLike] = useState(false);
    const [reviewForm, toggleReviewForm] = useState(false);
    const [isHoverReview, setModalReview] = useState(false);
    const [commentButtonVissibility, setCommentButtonVisibility] = useState(false)

    let timer = null;
    let newApplication = props.newApplication
    let specialistFound = props.specialistFound;
    let jobDone = props.jobDone;
    let isCanceled = props.isCanceled;

    useEffect(() => {
        if (newApplication) {
            if (timerDuration > 0 && !timer) {
                timer = setInterval(() => {
                    let seconds = (timerDuration % 60000) / 1000;
                    let minutes = Math.floor(timerDuration / 60000);
                    if (seconds < 10) {
                        seconds = "0" + seconds;
                    }
                    if (minutes < 10) {
                        minutes = "0" + minutes;
                    }
                    setSeconds(seconds);
                    setMinutes(minutes);
                    timerDuration -= 1000;
                    if (minutes === "00" && seconds === "00") {
                        clearInterval(timer);
                    }
                }, 1000);
            }
            if (timerDuration <= 0) {
                clearInterval(timer);
                setSeconds("00");
                setMinutes("00");
                timer = null;
            }
            return (() => {
                clearInterval(timer)
            })
        }
    }, []);

    const togglePopUp = () => {
        toggleReviewForm(!reviewForm);
    };

    const toggleReviewModalShow = () => {
        setModalReview(true);
    }

    const toggleReviewModalHide = () => {
        setModalReview(false);
    }
    
    useEffect(() => {
        if (props.isLike) setIsLike(true)
        else if (props.isDislike) setIsDisLike(true)
        else {
            setIsLike(false)
            setIsDisLike(false)
        }
    }, [dispatch])

    useEffect(() => {
        if (props.isLike) {
            setIsLike(true);
            setIsDisLike(false);
        }
        else if (props.isDislike) {
            setIsDisLike(true);
            setIsLike(false);
        }
        else {
            setIsLike(false)
            setIsDisLike(false)
        }
        if (props.commentResult) {
            setIsComment(true)
        }
        props.currentApplication?.comment? setCommentButtonVisibility(true): setCommentButtonVisibility(false);
    }, [props.commentResult, props.isDislike, props.isLike, dispatch])

    const isLikeHandler = () => {
        setIsLike(true);
        setIsDisLike(false);
        let obj = {
            document: props.hashApp,
            rate: 1
        }
        dispatch(leaveTheRate(obj))
    }
    const isDisLikeHandler = () => {
        setIsDisLike(true);
        setIsLike(false);
        let obj = {
            document: props.hashApp,
            rate: -1
        }
        dispatch(leaveTheRate(obj))
    }

    const applyCommentToSpecialist = async () => {

        if (props.commentValue === undefined || props.commentValue.trim() === '') {
            return;
        }
        const formData = new FormData()
        formData.append("document", props.hashApp)
        formData.append("message", props.commentValue.toString())
        console.log(props.hashApp, props.commentValue);
        await dispatch(addTheCommentToSpecialist(formData))
        setIsComment(true);
        toggleReviewForm(false)
        setCommentButtonVisibility(true)
        await window.location.reload();
    }
    const popUpWithBackground = (
        <div
            onClick={({target}) => {
                (target?.childNodes?.[0]?.className === 'ReviewPopUp__Container' ||
                    target?.className === 'PopUp__Background') &&
                toggleReviewForm(false);
            }}
            className='PopUp__Background'>
            <ReviewPopUp name={props.name} photo={props.photo} like={isLike} dislike={isDisLike}
                         clientName={props.clientName} currentApplication={props.currentApplication}
                         id={props.clientID} clicked={applyCommentToSpecialist}
                         commentValue={props.commentValue}
                         onChangeComment={props.onChangeComment}
                         commentButtonVissibility={commentButtonVissibility}
                         />
        </div>
    );

    const ratingBlock = (
        <div className='ratingBlock'>
            <p className='prompt'>оцените мою работу</p>
            <div className='options'>
                {isLike ? <div
                    className={`likeBtn likeBtn-active`}
                    onClick={isLikeHandler}/> : null}
                {isDisLike ? <div
                    className={`dislikeBtn dislikeBtn-active`}
                    onClick={isDisLikeHandler}/> : null}
                {props.currentApplication?.comment || commentButtonVissibility ?
                    <>
                        <CommentIcon  className='cursor-pointer' onClick={togglePopUp}/>
                        <div className={`comment-block ${isHoverReview ? 'show-modal' : 'hide-modal'}`}>
                            <div className={`comment-modal_block ${isHoverReview ? 'show-block' : 'hide-block'}`}>
                                <div className='comment-modal_text-block'>
                                    <p>{props.currentApplication.commentstr}</p>
                                </div>
                            </div>
                            <div className={`comment-block-bg ${isHoverReview ? 'show-modal' : 'hide-modal'}`}/>
                        </div>
                    </>
                    : null}
                {!isLike && !isDisLike ? <>
                    <div
                        className={`likeBtn`}
                        onClick={isLikeHandler}/>
                    <div
                        className={`dislikeBtn`}
                        onClick={isDisLikeHandler}/>
                </> : null
                }
            </div>
            {props.currentApplication?.comment ? null : <WhiteButton
                className='leaveOpinion'
                name={'Оставить отзыв'}
                clicked={togglePopUp}
            />}
            {reviewForm && popUpWithBackground}
        </div>
    );

    if (newApplication) {
        topComponent = (
            <div className="StatusNew">
                <div className="StatusNew-warn">
                    <h2 className="StatusNew__title">Ура, мы получили вашу заявку!</h2>
                    <p className="StatusNew_text">Сейчас Asista ищет подходящего айтишника из нашей команды, это займет
                        максимум 15 минут.</p>
                </div>
                <div className="StatusNew__timeBlock">
                    <span className="StatusNew__time StatusNew__time--numbers">{minutes}</span>
                    <span className="StatusNew__time">:</span>
                    <span className="StatusNew__time StatusNew__time--numbers">{seconds}</span>
                </div>
            </div>
        )
    } else {
        topComponent = (
            <div className="SpecialistWindow">
                    <div className={`SpecialistWindow-status-color ${specialistFound ? "backColor-orange" : ""}
                     ${jobDone ? "backColor-green" : ""} ${isCanceled ? "backColor-grey" : ""}
                `}></div>
                <div className="SpecialistWindow-body">
                    <h2 className={`SpecialistWindow-title ${specialistFound ? "color-orange" : ""} ${jobDone ? "color-green" : ""} ${isCanceled ? "color-grey" : ""}`}>{specialistFound ? "Заявка в работе" : ""}{jobDone ? "Заявка закрыта" : ""}{isCanceled ? "Заявка отменена" : ""}</h2>
                    <div className="SpecialistWindow-content">
                        <img className="SpecialistWindow-content-avatar" src = {props.photo}/>
                        <div className="SpecialistWindow-content-info">
                            {specialistFound ? <p className="SpecialistOnTheWay-text">Скоро с вами свяжется</p> : null}
                            <p className="name">{props.name}</p>
                            <div className="contact">
                                <p className="department">IT {props.phone}</p>
                                <p className="phone">+7 727 357 30 65</p>
                            </div>
                        </div>
                    </div>
                </div>
                {jobDone || isCanceled ? ratingBlock : null}
            </div>
        )
    }
    return (
        <>
            {topComponent}
        </>
    )
}

export default SpecialitsWindowStatus;