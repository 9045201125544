import React from 'react';
import './ErrorWindow.css';
import {useDispatch} from "react-redux";
import {goBack} from "connected-react-router";

const ErrorWindow = () => {
    const dispatch = useDispatch()
    // const isConnected = window.navigator.onLine
    // if (isConnected) {
    //     dispatch(goBack())
    // }



    return (
        <div className="ErrorWindow">
            <div className="ErrorWindow__container">
                <h1 className="ErrorWindow__title">Что-то пошло не так</h1>
                <p className="ErrorWindow__text">Страница не загружается. Не отчаивайтесь!</p>
                <p className="ErrorWindow__text">Вы можете оставить заявку в приложении <a href="https://itsupport.kz/TeamViewer%20IT%20Support%20idc6uwrqhv.exe" className="ErrorWindow__link">TeamViewer</a> или по телефону:</p>

                <div className="ErrorWindow__contacts">
                    <div className="ErrorWindow__contactSide">
                        <p className="ErrorWindow__phone">+7 727 357 30 65</p>
                        <p className="ErrorWindow__city">в Алматы</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ErrorWindow;
