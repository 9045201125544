import React from 'react';
import './NoResults.css';


const NoResults = () => {
    return (
        <div className="NoResults">
            <div className="NoResults__content">
                <div className="NoResults__ring" />
                <h2 className="NoResults__title">Ничего не найдено.</h2>
                {/*<p className="NoResults-text">Заявки с номером IT-011220-039862 в базе IT Support нет, <br/>*/}
                {/*    пожалуйста перепроверьте корректность номера и попробуйте снова,<br/>*/}
                {/*    или воспользуйтесь поиском по дате.</p>*/}
            </div>
        </div>
    )
}


export default NoResults;