import './TranslationsForm.css';

export const TranslationsForm = () => {
    return (
        <div className="TranslationsForm">
            <div className="TranslationsForm-manipulations">
                <video className="translation" controls="controls"> </video>
                <button className="start">Начать<br/>трансляцию</button>
            </div>
            <div className="TranslationsForm-body">
                <div className="rooms">
                    <div className="icon">
                    </div>
                    <div className="titlesAndContents">
                        <h1 className="title">Личная комната</h1>
                        <p className="content">Описание комнаты или комментарий</p>
                    </div>
                    <div className="more">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="rooms">
                    <div className="icon">
                    </div>
                    <div className="titlesAndContents">
                        <h1 className="title">Корпоративная комната</h1>
                        <p className="content">Описание комнаты или комментарий</p>
                    </div>
                    <div className="more">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="rooms">
                    <button className="addIcon">
                        +
                    </button>
                    <div className="addTitles">
                        <h1 className="addTitle">Создать новую комнату</h1>
                    </div>
                    <div className="more">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

            </div>
            <div className="TranslationsForm-footer">
                <div>
                    <h1 className="history">История трансляций</h1>
                </div>
                <div>
                    <table className="translationTable">
                        <tr className="first">
                            <th className="headers">Название</th>
                            <th className="headers">Длина записи</th>
                            <th className="headers">Видимость</th>
                            <th className="headers">Форматы</th>
                        </tr>
                        <tr className="body">

                        </tr>
                    </table>
                    <h1 className="empty">История трансляций пуста</h1>
                </div>
            </div>
        </div>
    )
}