import React from 'react';
import Logo from '../Logo/Logo';
import WhiteButton from '../WhiteButton/WhiteButton';
import './LayoutApplicationPage.css';
import {LogoSidebar} from "../../../constants/UI/LogoSidebar";

const LayoutApplicationPage = (props) => {

    return (
        <div className="LayoutApplicationPage">
            <LogoSidebar/>
            <div className="LayoutApplicationPage_sidebar">
                <Logo
                    logoLink={props.createNewApplication}
                />
                <div className="LayoutApplicationPage__prev-menu">{props.left}</div>
                <div className={"WhiteButton--heightStabilizer"}/>
                <WhiteButton
                    name="Создать новую заявку"
                    clicked={props.createNewApplication}
                    hide={props.hideButton}
                />
                {/* <button className="create-conferenceBtn">Создать видеоконференцию</button> */}
                <WhiteButton
                    name={"Посмотреть историю моих заявок"}
                    clicked={props.goToApplicationHistory}
                />
                {/* <div className="WhiteButton-socialContent">
                    <p>Мы в социальных сетях</p>
                    <div className="social-instagram"></div>
                    <div className="social-meta"></div>
                    <div className="social-linkedin"></div>
                    <div className="social-youtube"></div>
                </div> */}
            </div>
            <div className="LayoutApplicationPage__main">
                <div className="LayoutApplicationPage__top">{props.top}</div>
                <div className="LayoutApplicationPage__center">{props.center}</div>
                <div className="LayoutApplicationPage__bottom">{props.bottom}</div>
            </div>
        </div>
    )
}

export default LayoutApplicationPage;
