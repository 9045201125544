import React from "react";
import './ITSGContact.css';

export const ITSGContact = () => {
    return (
        <div className="about">
            <p className="website">itsupport.kz</p>
            <p className="chronology">IT Support group 2006-2022</p>
            <div className="itsg-logo"></div>
        </div>
    )
}