import './Trials.css';

const Trials = (props) => {

    return (
        <div className='trials_block'>
            <div className='trials-text_block'>
                <p className='trials-text'>
                    {((props.trialInfo?.avitimTrial && props.trialInfo?.invisionTrial ) || (props.clientAccess?.avitimClient && props.trialInfo?.invisionTrial)|| (props.clientAccess?.invisionClient && props.trialInfo.avitimTrial))? 
                    "У вас закончились пробные консультации"
                    : (!props.trialInfo?.invisionTrial && !props.clientAccess?.invisionClient)? 
                    "У вас есть бесплатные консультации по WEB"
                    : (!props.trialInfo?.avitimTrial && !props.clientAccess?.avitimClient)? 
                    "У вас есть бесплатные консультации по 1C" 
                    : (!props.trialInfo?.avitimTrial && !props.trialInfo?.invisionTrial && !props.clientAccess?.invisionClient && !props.clientAccess?.avitimClient)?
                    "У вас есть бесплатные консультации по 1С и WEB": null} 
                    </p>
            </div>
            <button className='read-more_btn' onClick={props.modalHandler}>Узнать подробнее</button>
        </div>
    )
}

export default Trials;