import './ProgramFilesForm.css';

const ProgramFilesForm = (props) => {

    const versions = (
        <>
            <input type="radio" className="version-first" value="Microsoft Office 365" name={props.versionName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/> <p
            className="version-first-text">Microsoft Office 365</p>
            <input type="radio" className="version-second" value="Office 2019" name={props.versionName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/><p
            className="version-second-text">Office 2019</p>
            <input type="radio" className="version-third" value="Office 2016" name={props.versionName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/> <p
            className="version-third-text">Office 2016</p>
            <input type="radio" className="version-fourth" value="Office 2013" name={props.versionName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/><p
            className="version-fourth-text">Office 2013</p>
            <input type="radio" className="version-fifth" value="Office 2011" name={props.versionName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/><p
            className="version-fifth-text">Office 2011</p>
        </>
    )
    const antiviruses = (
        <>
            <input type="radio" className="antivirusRadio-first" value="Касперский" name={props.antivirusName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/> <p
            className="antivirusRadio-first-text">Касперский</p>
            <input type="radio" className="antivirusRadio-second" value="Есет" name={props.antivirusName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/><p
            className="antivirusRadio-second-text">Есет</p>
            <input type="radio" className="antivirusRadio-third" value="Доктор Веб" name={props.antivirusName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/> <p
            className="antivirusRadio-third-text">Доктор Веб</p>
            <input type="radio" className="antivirusRadio-fourth" value="Аваст" name={props.antivirusName}
                   onChange={props.antivirusChange} onClick={props.leaveMouseSelect}/><p
            className="antivirusRadio-fourth-text">Аваст</p>
        </>
    )

    return (
        <div className="ProgramFilesForm">
            <div className="ProgramFilesForm-header">
                <h1 className="topic">
                    Установить ПО
                </h1>
                <h2 className="head">
                    Microsoft Office
                </h2>
                <select onClick={props.enterMouseSelect} className="ProgramFilesForm-select"
                        name={props.versionName} style={{"color" : props.checked["version"] ? "black" : "#c0c0c0"}}>
                    <option
                        hidden="true">{props.checked["version"] ? props.checked["version"] : "Выберите версию"}</option>
                </select>
                <div className="ProgramFilesForm-hiddenOptionBackgroundVersion"
                     style={props.isSelected === "version" ? {"display": "block"} : {"display": "none"}}>
                    {versions}
                </div>
            </div>
            <div className="ProgramFilesForm-body">
                {props.showMC ?
                    <div className="ProgramFilesForm-microsoft">
                        <div className="ProgramFilesForm-microsoft-apps">
                            <div className="microsoftIcons">
                                <div className="mc-word-image"/>
                                <p className="text" title="word">Microsoft Word</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["word"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="microsoftApps-hiddenSelectButton"
                                style={props.microsoftState["word"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-excel-image"/>
                                <p className="text" title="excel">Microsoft Excel</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["excel"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["excel"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-powerpoint-image"/>
                                <p className="text" title="powerpoint">Microsoft PowerPoint</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["powerpoint"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="microsoftApps-hiddenSelectButton"
                                style={props.microsoftState["powerpoint"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-onenote-image"/>
                                <p className="text" title="onenote">Microsoft OneNote</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["onenote"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["onenote"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-project-image"/>
                                <p className="text" title="project">Microsoft Project</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["project"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["project"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-access-image"/>
                                <p className="text" title="access">Microsoft Access</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["access"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["access"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-publisher-image"/>
                                <p className="text" title="publisher">Microsoft Publisher</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["publisher"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["publisher"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-outlook-image"/>
                                <p className="text" title="outlook">Microsoft Outlook</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["outlook"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["outlook"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                            <div className="microsoftIcons">
                                <div className="mc-visio-image"/>
                                <p className="text" title="visio">Microsoft Visio</p>
                                <input type="checkbox" name={props.microsoftName} className="microsoftAppsCheckbox"
                                       onChange={props.checkboxChange}/> <span
                                className="microsoftApps-radioButton"
                                style={props.microsoftState["visio"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                                className="programFiles-hiddenSelectButton"
                                style={props.microsoftState["visio"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            </div>
                        </div>
                        <div className= "mc">
                            <input type="text" className="moreMicrosoft" placeholder="Дополнительно"/>
                        </div>
                    </div>
                    :
                    null
                }
                <h1 className="header">Популярные приложения</h1>
                <div className="ProgramFilesForm-body-programs">
                    <div className="portion">
                        <div className="adobe-image"/>
                        <p className="text" title="adobeAcrobat">Adobe Acrobat Pro 11</p>
                        <input type="checkbox" name={props.popAppsName} className="programFilesCheckbox"
                               onChange={props.checkboxChange}/> <span
                        className="programFiles-radioButton"
                        style={props.appsState["adobeAcrobat"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                        className="programFiles-hiddenSelectButton"
                        style={props.appsState["adobeAcrobat"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    </div>
                    <div className="portion">
                        <div className="winrar-image"/>
                        <p className="text" title="winRAR">WinRAR</p>
                        <input type="checkbox" name={props.popAppsName} className="programFilesCheckbox"
                               onChange={props.checkboxChange}/> <span
                        className="programFiles-radioButton"
                        style={props.appsState["winRAR"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                        className="programFiles-hiddenSelectButton"
                        style={props.appsState["winRAR"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    </div>
                    <div className="portion">
                        <div className="skype-image"/>
                        <p className="text" title="skype">Skype</p>
                        <input type="checkbox" name={props.popAppsName} className="programFilesCheckbox"
                               onChange={props.checkboxChange}/> <span
                        className="programFiles-radioButton"
                        style={props.appsState["skype"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                        className="programFiles-hiddenSelectButton"
                        style={props.appsState["skype"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    </div>
                    <div className="portion">
                        <div className="chrome-image"/>
                        <p className="text" title="chrome">Chrome</p>
                        <input type="checkbox" name={props.popAppsName} className="programFilesCheckbox"
                               onChange={props.checkboxChange}/> <span
                        className="programFiles-radioButton"
                        style={props.appsState["chrome"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                        className="programFiles-hiddenSelectButton"
                        style={props.appsState["chrome"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    </div>
                </div>
                <div className="ProgramFilesForm-body-antivirus">
                    <div className="modals">
                        <h1 className="header">Антивирус <span className="modalIcon" onMouseEnter={props.enterMouse}
                                                               onMouseLeave={props.leaveMouse}>!</span></h1>
                        <p className="hiddenModalText"
                           style={props.showModal ? {"display": "block"} : {"display": "none"}}>За лицензионную подписку
                            платит клиент</p>
                    </div>
                    <div>
                        <select onClick={props.enterMouseSelect} className="ProgramFilesForm-select"
                                name={props.antivirusName} style={{"color" : props.checked["antivirus"] ? "black" : "#c0c0c0"}}>
                            <option
                                hidden="true">{props.checked["antivirus"] ? props.checked["antivirus"] : "Выбрать из списка"}</option>
                        </select>
                        <div className="ProgramFilesForm-hiddenOptionBackground"
                             style={props.isSelected === "antivirus" ? {"display": "block"} : {"display": "none"}}>
                            {antiviruses}
                        </div>
                        <input type = "text" className="moreAntivirus" placeholder="Другое"/>
                    </div>
                </div>
                <div className="ProgramFilesForm-body-report">
                    <h1 className="header">Отчетность</h1>
                    <div className="checkboxes">
                        <div className="info"><input type="checkbox" name={props.reportingsName}
                                                     className="programFilesCheckbox"
                                                     onChange={props.checkboxChange}/> <span
                            className="programFiles-radioButton-second"
                            style={props.reportState["NCALayer"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                            className="programFiles-hiddenSelectButton"
                            style={props.reportState["NCALayer"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            <p className="report-text"
                            title="NCALayer">NCALayer</p>
                        </div>
                        <div className="info"><input type="checkbox" name={props.reportingsName}
                                                     className="programFilesCheckbox"
                                                     onChange={props.checkboxChange}/> <span
                            className="programFiles-radioButton-second"
                            style={props.reportState["COHO"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                            className="programFiles-hiddenSelectButton"
                            style={props.reportState["COHO"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            <p className="report-text"
                            title="COHO">COHO</p>
                        </div>
                        <div className="info"><input type="checkbox" name={props.reportingsName}
                                                     className="programFilesCheckbox"
                                                     onChange={props.checkboxChange}/> <span
                            className="programFiles-radioButton-second"
                            style={props.reportState["cabinetSalyk"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                            className="programFiles-hiddenSelectButton"
                            style={props.reportState["cabinetSalyk"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                            <p className="report-text"
                            title="cabinetSalyk">Кабинет
                            налогоплательщика</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ProgramFilesForm-body-bankPrograms">
                <h1 className="header">Банковские программы</h1>
                <input type="text" className="bankPrograms-input"
                       placeholder="Название банка" name={props.bankProgramName}
                       onChange={props.bankProgramChange}/>
            </div>
            <div className="ProgramFilesForm-footer">
                <h1 className="header">Дополнительные программы</h1>
                <textarea style={{"outline": "none"}} className="moreProgram-input" cols="40" rows="5"
                          placeholder="Указать наименование" name={props.moreProgramName}
                          onChange={props.moreProgramChange}/>
            </div>
        </div>
    )
}

export default ProgramFilesForm;