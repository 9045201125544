import './EmailSettingsForm.css';

const EmailSettingsForm = (props) => {

    return (
        <div className="EmailSettingsForm">
            <div className="EmailSettingsForm-header">
                <p className="EmailSettingsForm-title">
                    Настройки почты, обязательное поле
                </p>
                <h1 className="EmailSettingsForm-email-settings">
                    Учетная запись ПОЧТЫ *
                </h1>
                <input type="text" className="EmailSettingsForm-email-input" name = {props.emailName} onChange={props.emailChange} placeholder="i.familiya@domain.com"/>
                <div className="EmailSettingsForm-radios">
                    <input type="radio" name={props.passwordModifierName}
                           className="emailRadioButton"
                           onChange={props.passwordModifier}/> <span
                    className="email-radioButton"
                    style={props.checked["passModifier"]["checked"] && props.checked["passModifier"]["option"] === "Почту надо создать" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="email-hiddenSelectButton"
                    style={props.checked["passModifier"]["checked"] && props.checked["passModifier"]["option"] === "Почту надо создать" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="EmailSettingsForm-radio-text" title = "option">Почту надо создать</p>
                    <input type="radio" name={props.passwordModifierName}
                           className="emailRadioButton"
                           onChange={props.passwordModifier}/> <span
                    className="email-radioButton"
                    style={props.checked["passModifier"]["checked"] && props.checked["passModifier"]["option"] === "Просто обновить пароль" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="email-hiddenSelectButton"
                    style={props.checked["passModifier"]["checked"] && props.checked["passModifier"]["option"] === "Просто обновить пароль" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="EmailSettingsForm-radio-text" title = "option">Просто обновить пароль</p>
                </div>
            </div>
            <div className="EmailSettingsForm-footer">
                <div className="EmailSettingsForm-title-second">
                    <h1 className="header">
                        Группа e-mail рассылки
                    </h1>
                    <div className="RefreshOrCreateForm-modals">
                        <h1 className="header"><span className="modalIcon" onMouseEnter={props.enterMouseQuestion}
                                                     onMouseLeave={props.leaveMouseQuestion}>?</span></h1>
                        <p className="hiddenModalText"
                           style={props.show ? {"display": "block"} : {"display": "none"}}>ID присваевается оборудованию,
                            <br/> а не сотруднику</p>
                    </div>
                </div>
                <input type="text" className="EmailSettingsForm-email-input" name = {props.spamEmailName} onChange={props.spamEmailChange} placeholder="@top, @buhgalteria, @it"/>
                <div className="EmailSettingsForm-radios">
                    <input type="radio" name={props.outlookSetName}
                           className="emailRadioButton"
                           onChange={props.outlookSetChange}/> <span
                    className="email-radioButton"
                    style={props.checked["outlookSetting"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="email-hiddenSelectButton"
                    style={props.checked["outlookSetting"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="EmailSettingsForm-radio-text">Настроить почту в Outlook</p>
                </div>
                {props.isWrong ? <p className="hiddenErrorText">Некорректный формат один из почт!</p> : null }
            </div>
        </div>
    )
}

export default EmailSettingsForm;