import LayoutApplicationPage from "../../ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import SpecialitsWindowStatus from "../../ApplicationPageComponents/SpecialitsWindowStatus/SpecialitsWindowStatus";
import axios from "axios";
import WithLoader from "../../../hoc/WithLoader/WithLoader";

const OpenAccessStatusPage = (props) => {

    const top = (
        <SpecialitsWindowStatus/>
    )
    const requestDetails = (
        <div>
            <h1>Детали заявки</h1>
            <div>
                <div>Ответственный отдел</div>
                <div>Удаленная поддержка</div>
            </div>
            <div>
                <div>Тема</div>
                <div>Открытие доступов</div>
            </div>
            <div>
                <div>Подробности</div>
                <div>Заполнил акт открытия доступов по новому сотруднику. Все  по пунктам, но не уверенны с почтовыми группами. Дополним их позже.</div>
            </div>
        </div>
    )

    const refreshOrCreate = (
        <div>
            <h1></h1>
            <div>
                <p></p>
                <p></p>
            </div>
            <h1></h1>
            <div>
                <p></p>
                <p></p>
            </div>
            <p></p>
        </div>
    )
    const newUserInfo = (
        <div>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </div>
    )
    const emailData = (
        <div>
            <h1>
            </h1>
            <p></p>
        </div>
    )
    const websiteData = (
        <div>
            <h1></h1>
            <h1></h1>
            <div>
                <h1></h1>
                <div>
                    <p></p>
                    <p></p>
                </div>
            </div>
        </div>
    )
    const access1C = (
        <div>
            <h1></h1>
            <p></p>
            <p></p>
        </div>
    )
    const internalNumber = (
        <div>
            <h1></h1>
            <p></p>
            <p></p>
        </div>
    )
    const setupProgram = (
        <div>
            <h1></h1>

        </div>
    )

    const antivirus = (
        <div>
            <h1></h1>
            <p></p>
        </div>
    )
    const bankPrograms = (
        <div>
            <h1></h1>
            <p></p>
        </div>
    )
    const additionalDetails = (
        <div>
            <h1></h1>
            <div></div>
        </div>
    )

    const center = (
        <div>
            {requestDetails}
            {refreshOrCreate}
            {newUserInfo}
            {emailData}
            {websiteData}
            {access1C}
            {internalNumber}
            {setupProgram}
            {antivirus}
            {bankPrograms}
            {additionalDetails}
        </div>
    )

    return (
        <LayoutApplicationPage top = {top} center = {center}/>
    )
}

export default WithLoader(OpenAccessStatusPage, axios);