import React, {useEffect, useState} from 'react';
import './TrialSpecialistWindowStatus.css';

import ReviewPopUp from '../../ApplicationPageComponents/ReviewPopUp/ReviewPopUp';
import WhiteButton from '../../ApplicationPageComponents/WhiteButton/WhiteButton';
import {ReactComponent as CommentIcon} from "../../../assets/icons/comment_icon.svg";
import { addTheCommentToSpecialist } from '../../../Store/ApplicationsReducer/applicationsActions';
import {useDispatch} from 'react-redux';
import { leaveTheRate } from '../../../Store/ApplicationsReducer/applicationsActions';

const TrialSpecialitsWindowStatus = (props) => {
    let timerDuration = props.timerDuration; //15 минут
    const dispatch = useDispatch();
    let topComponent;
    const [seconds, setSeconds] = useState('00');
    const [minutes, setMinutes] = useState(15);
    const [, setIsComment] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [isDisLike, setIsDisLike] = useState(false);
    const [reviewForm, toggleReviewForm] = useState(false);
    const [isHoverReview, setModalReview] = useState(false);
    const [commentButtonVissibility, setCommentButtonVisibility] = useState(false)
    let timer = null;
    let newApplication = props.newApplication;
    let specialistFound = props.specialistFound;
    let jobDone = props.jobDone;
    let isCanceled = props.isCanceled;
    let onPause = props.onPause;

    useEffect(() => {
        if (newApplication) {
            if (timerDuration > 0 && !timer) {
                timer = setInterval(() => {
                    let seconds = (timerDuration % 60000) / 1000;
                    let minutes = Math.floor(timerDuration / 60000);
                    if (seconds < 10) {
                        seconds = '0' + seconds;
                    }
                    if (minutes < 10) {
                        minutes = '0' + minutes;
                    }
                    setSeconds(seconds);
                    setMinutes(minutes);
                    timerDuration -= 1000;
                    if (minutes === '00' && seconds === '00') {
                        clearInterval(timer);
                    }
                }, 1000);
            }
            if (timerDuration <= 0) {
                clearInterval(timer);
                setSeconds('00');
                setMinutes('00');
                timer = null;
            }
            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    useEffect(() => {
        if (props.isLike) setIsLike(true);
        else if (props.isDislike) setIsDisLike(true);
        else {
            setIsLike(false);
            setIsDisLike(false);
        }
    }, [props.isDislike, props.isLike]);

    useEffect(() => {
        if (props.isLike) {
            setIsLike(true);
            setIsDisLike(false);
        } else if (props.isDislike) {
            setIsDisLike(true);
            setIsLike(false);
        } else {
            setIsLike(false);
            setIsDisLike(false);
        }
        if (props.commentResult) {
            setIsComment(true);
        }
        props.currentApplication?.comment? setCommentButtonVisibility(true): setCommentButtonVisibility(false);
    }, [props.commentResult, props.isDislike, props.isLike]);

    const isLikeHandler = () => {
        setIsLike(true);
        setIsDisLike(false);
        let obj = {
            document: props.hashApp,
            rate: 1,
        };
        dispatch(leaveTheRate(obj));
    };
    const isDisLikeHandler = () => {
        setIsDisLike(true);
        setIsLike(false);
        let obj = {
            document: props.hashApp,
            rate: -1,
        };
        dispatch(leaveTheRate(obj));
    };

    const togglePopUp = () => {
        toggleReviewForm(!reviewForm);
    };

    const toggleReviewModalShow = () => {
        setModalReview(true);
    }

    const toggleReviewModalHide = () => {
        setModalReview(false);
    }
    const applyCommentToSpecialist = () => {

        if (props.commentValue === undefined || props.commentValue.trim() === '') {
            return;
        }
        const formData = new FormData()
        formData.append("document", props.hashApp)
        formData.append("message", props.commentValue.toString())
        console.log(props.hashApp, props.commentValue);
        dispatch(addTheCommentToSpecialist(formData))
        setIsComment(true);
        toggleReviewForm(false)
        setCommentButtonVisibility(true)
    }
    const popUpWithBackground = (
        <div
            onClick={({target}) => {
                (target?.childNodes?.[0]?.className === 'ReviewPopUp__Container' ||
                    target?.className === 'PopUp__Background') &&
                toggleReviewForm(false);
            }}
            className='PopUp__Background'>
            <ReviewPopUp name={props.name} photo={props.photo} like={isLike} dislike={isDisLike}
                         clientName={props.clientName} currentApplication={props.currentApplication}
                         id={props.clientID} clicked={applyCommentToSpecialist}
                         commentValue={props.commentValue}
                         commentButtonVissibility={commentButtonVissibility}
                         onChangeComment={props.onChangeComment}
                         />
        </div>
    );

    const ratingBlock = (
        <div className='ratingBlock'>
            <p className='prompt'>оцените мою работу</p>
            <div className='options'>
                {isLike ? <div
                    className={`likeBtn likeBtn-active`}
                    onClick={isLikeHandler}/> : null}
                {isDisLike ? <div
                    className={`dislikeBtn dislikeBtn-active`}
                    onClick={isDisLikeHandler}/> : null}
                {props.currentApplication?.comment || commentButtonVissibility?
                    <>
                        <CommentIcon onMouseEnter={toggleReviewModalShow} onMouseLeave={toggleReviewModalHide}/>
                        <div className={`comment-block ${isHoverReview ? 'show-modal' : 'hide-modal'}`}>
                            <div className={`comment-modal_block ${isHoverReview ? 'show-block' : 'hide-block'}`}>
                                <div className='comment-modal_text-block'>
                                    <p>{props.currentApplication.commentstr}</p>
                                </div>
                            </div>
                            <div className={`comment-block-bg ${isHoverReview ? 'show-modal' : 'hide-modal'}`}/>
                        </div>
                    </>
                    : null}
                {!isLike && !isDisLike ? <>
                    <div
                        className={`likeBtn`}
                        onClick={isLikeHandler}/>
                    <div
                        className={`dislikeBtn`}
                        onClick={isDisLikeHandler}/>
                </> : null
                }
            </div>
            {props.currentApplication?.comment ? null : <WhiteButton
                className='leaveOpinion'
                name={'Оставить отзыв'}
                clicked={togglePopUp}
            />}
            {reviewForm && popUpWithBackground}
        </div>
    );

    if (newApplication) {
        topComponent = (
            <div className='StatusNew'>
                <div className='StatusNew-warn'>
                    <h2 className='StatusNew__title'>Ура, мы получили вашу заявку!</h2>
                    <p className='StatusNew_text'>
                        Сейчас Asista обрабатывает вашу заявку, это займет какое то время
                    </p>
                </div>
            </div>
        );
    } else {
        topComponent = (
            <div className='SpecialistWindow'>
                <div
                    className={`SpecialistWindow-status-color ${
                        specialistFound ? 'backColor-orange' : ''
                    }
                     ${jobDone ? 'backColor-green' : ''} ${
                        isCanceled ? 'backColor-grey' : ''
                    }
                    ${onPause ? 'backColor-aqua' : ''}
                `}
                />
                <div className='SpecialistWindow-body'>
                    <h2
                        className={`SpecialistWindow-title ${
                            specialistFound ? 'color-orange' : ''
                        } ${jobDone ? 'color-green' : ''} ${
                            isCanceled ? 'color-grey' : ''
                        } ${onPause ? 'color-aqua' : ''}`}>
                        {specialistFound ? 'Заявка в работе' : ''}
                        {jobDone ? 'Заявка закрыта' : ''}
                        {isCanceled ? 'Заявка отменена' : ''}
                        {onPause ? 'Заявка на паузе' : ''}
                    </h2>
                    <div className='SpecialistWindow-content'>
                        <img
                            className='SpecialistWindow-content-avatar'
                            src={props.photo}
                            alt={props.name}
                        />
                        <div className='SpecialistWindow-content-info'>
                            {specialistFound || onPause ? (
                                <p className='SpecialistOnTheWay-text'>Скоро с вами свяжется</p>
                            ) : null}
                            <p className='name'>{props.name}</p>
                            <div className='contact'>
                                <p className='department'>IT {props.phone}</p>
                                <p className='phone'>+7 727 390 1112</p>
                            </div>
                        </div>
                    </div>
                </div>
                {jobDone || isCanceled ? ratingBlock : null}
            </div>
        );
    }
    return <>{topComponent}</>;
};

export default TrialSpecialitsWindowStatus;
