export const openAccessData = {
    instrument: "",
    user: "",
    locationOldUser: "",
    returnAccessOldUser: false,
    MSN: "",
    phone: "",
    rank: "",
    dateOfStart: "",
    working: false,
    deviceName: "",
    email: "",
    spamEmail: "",
    prevEmployee : "",
    passModifier: {
        option: "",
        checked: false
    },
    outlookSetting: false,
    createAccount: false,
    userAccess: {
        option: "",
        checked: false
    },
    mutualFiles: [],
    access1C: {
        option: "",
        checked: false
    },
    location1C: "",
    internalNumber: "",
    deviceModifier: {
        option: "",
        checked: false
    },
    version: "",
    popularApps: {
        adobeAcrobat: false,
        winRAR: false,
        skype: false,
        chrome: false
    },
    antivirus: "",
    reportings: {
        NCALayer: false,
        COHO: false,
        cabinetSalyk: false
    },
    microsoft: {
        word: false,
        powerpoint: false,
        onenote: false,
        visio: false,
        project: false,
        access: false,
        publisher: false,
        outlook: false,
        excel: false
    },
    bankProgram: "",
    moreProgram: "",
    comment: ""
}