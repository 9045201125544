import './Access1CForm.css';

const Access1CForm = (props) => {

    return (
        <div className="Access1CForm">
            <h1 className="header">
                Доступ к 1C
            </h1>
            <div className="Access1CForm-radios">
                <div className="radios">
                    <input type="radio" name={props.accessName} className="access1cRadio"
                           onChange={props.accessChange} /> <span
                    className="access1c-radioButton"
                    style={props.checked["access1C"]["checked"] && props.checked["access1C"]["option"] === "Желтый значок" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="access1c-hiddenSelectButton"
                    style={props.checked["access1C"]["checked"] && props.checked["access1C"]["option"] === "Желтый значок" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="Access1CForm-radios-text" title = "option">Желтый значок</p>
                </div>
                <div className="radios">
                    <input type="radio" name={props.accessName} className="access1cRadio"
                           onChange={props.accessChange} /> <span
                    className="access1c-radioButton"
                    style={props.checked["access1C"]["checked"] && props.checked["access1C"]["option"] === "Синий значок" ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="access1c-hiddenSelectButton"
                    style={props.checked["access1C"]["checked"] && props.checked["access1C"]["option"] === "Синий значок" ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="Access1CForm-radios-text" title = "option">Синий значок</p>
                </div>
            </div>
            <input type="text" className="Access1CForm-location1C" name={props.locationName}
                   onChange={props.locationChange} placeholder="Где находится 1С?"/>
        </div>
    )
}

export default Access1CForm;