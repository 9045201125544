import {Route, Switch, Redirect} from 'react-router';
import ErrorWindow from './Components/ApplicationPageComponents/ErrorWindow/ErrorWindow';
import ApplicationPage from './Containers/ApplicationPage/ApplicationPage';
import SearchResultsPage from './Containers/SearchResultsPage/SearchResultsPage';
import UserLoginPage from './Containers/UserLoginPage/UserLoginPage';
import {useSelector} from "react-redux";
import OpenAccessPage from "./Containers/OpenAccessPage/OpenAccessPage";
import PersonalProfilePage from "./Containers/PersonalProfilePage/PersonalProfilePage.js";
import MainPage from "./Containers/MainPage/MainPage.js";
import ConferencePage from "./Containers/ConferencePage/ConferencePage";
import OpenAccessStatusPage from "./Components/OpenAccessPageComponent/OpenAccessStatusPage/OpenAccessStatusPage";
import TrialForm from "./Components/TrialTicketComponents/TrialForming/TrialForm";

function App() {
    const forgetMe = useSelector(state => state.applications.forgetMe)
    // Это событие на кнопке "Запомнить Меня"
    if (forgetMe) {
        window.addEventListener("beforeunload", function (e) {
            localStorage.clear()
            return undefined;
        });
    }

// Asista - Avitim
    return (
        <>
            <Switch>
                <Route path="/" exact component={SearchResultsPage}/>
                <Route path="/search" exact component={SearchResultsPage}/>
                <Route path="/login" exact component={UserLoginPage}/>
                <Route path="/login/:id" exact component={UserLoginPage}/>
                <Redirect from="/application/:id/new" to="/prelogin/:id" />
                <Route path="/application/:id/:hash" component={ApplicationPage}/>
                <Route path="/error" component={ErrorWindow}/>
                <Route path="/:id/access/open" component={OpenAccessPage}/>
                <Route path="/prelogin/:id" component={MainPage}/>
                <Route path="/profile" component={PersonalProfilePage}/>
                <Route path="/conference" component={ConferencePage}/>
                <Route path="/access/open/status" component={OpenAccessStatusPage}/>
                <Route path="/:id/trial/:hash" component={TrialForm}/>
            </Switch>
        </>
    );
}

export default App;
