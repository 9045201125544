import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import Logo from "../../Components/ApplicationPageComponents/Logo/Logo";
import './MainPage.css';
import {ITSupport} from "../../Components/MainPageComponents/ITSupport/ITSupport";
import {AVITIM} from "../../Components/MainPageComponents/AVITIM/AVITIM";
import {INVISION} from "../../Components/MainPageComponents/INVISION/INVISION";
import {VCC} from "../../Components/MainPageComponents/VCC/VCC";
import {FooterInfo} from "../../constants/Contact/FooterInfo/FooterInfo";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {clearMyInterval, setUserItsupportID, postToContinueWork, getClientCompaniesAccess, getClientInfo, getClientName, getTrialInfo, setContinueWork} from "../../Store/ApplicationsReducer/applicationsActions";
import {push} from "connected-react-router";
import { useState } from "react";
import {getCompanyData} from "../../Store/CompanyDataReducer/companyActions";
import { useParams } from "react-router";
import Trials from "../../Components/MainPageComponents/Trials/Trials";

const MainPage = (props) => {

    const id = props.match.params.id;
    const dispatch = useDispatch();
    const continueWorkReq = useSelector(state=>state.applications.continueWork)
    const phone = useSelector(state=> state.applications.clientPhone)
    const trialInfo = useSelector(state=>state.applications.trialInfo)
    const clientAccess = useSelector(state=>state.applications.clientAccess)
    const itsupportID = useSelector(state=>state.applications.userItsupportID)
    const [sentRequestToContinueWork, setSentRequestToContinueWork] = useState(
        {
            invision: continueWorkReq?.invision,
            avitim:continueWorkReq?.avitim,
            itsupport:continueWorkReq?.itsupport
        }
    )
    const [itsupportContinue, setItsupportContinue] = useState(false)
    const [invisionContinue, setInvisionContinue] = useState(false)
    const openStyle = {
        'opacity': 1,
        'backdrop-filter': 'blur(2px)',
        'animation': 'openModal linear .25s'
    }

    const [infoModal, setInfoModal] = useState(false);
    const [continueModal, setContinueModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const modalHandler = () => {
        setInfoModal(!infoModal);
    }

    const closeModals = ()=>{
        setInfoModal(false);
        setContinueModal(false);
        setConfirmModal(false);
        setItsupportContinue(false);
        setInvisionContinue(false)
    }

    const continueModalHandler = (company) => {
        if(company==="itsupport" && (!continueWorkReq.itsupport || sentRequestToContinueWork.itsupport)){
            setItsupportContinue(true)
            setContinueModal(true);
        }
        if(company==="invision" && (!continueWorkReq.invision || !sentRequestToContinueWork.invision)){
            setInvisionContinue(true)
            setContinueModal(true);
            
        }
        if((company==="invision" && (continueWorkReq.invision || sentRequestToContinueWork.invision)) 
        || company==="itsupport" && (continueWorkReq.itsupport || sentRequestToContinueWork.itsupport)){
            setConfirmModal(true)
        }
        
        
    }

    useEffect(() => {
        dispatch(getClientName(id))
        dispatch(getClientInfo(id))
    }, [dispatch, id]);

    useEffect(()=>{
        // if(!phone || phone===null){
        //     dispatch(push('/error'))
        // }
        dispatch(getTrialInfo(phone))
        dispatch(getClientCompaniesAccess(phone));
        dispatch(setUserItsupportID(phone))
    }, [dispatch, phone])

    const postApplicationToContinue = async () =>{
    
        if(itsupportContinue){
            const data = {
                id: id,
                fromCompany: "avitim",
                toCompany: "itsupport" ,
                phone: phone ,
            }
            setContinueWork({...continueWorkReq, itsupport: true})
            setSentRequestToContinueWork({...continueWorkReq, itsupport: true})
            dispatch(postToContinueWork(data))
            setConfirmModal(true);
        }else if(invisionContinue){
            const data = {
                id: id,
                fromCompany: "avitim",
                toCompany: "invision" ,
                phone: phone ,
            }
            setContinueWork({...continueWorkReq, invision: true})
            setSentRequestToContinueWork({...continueWorkReq, invision: true})
            dispatch(postToContinueWork(data))
            setConfirmModal(true);
        }
        
    }
    const center = (
        <div className="MainPage-body">
            <div className="MainPage-body-header">
                <Logo> </Logo>
            </div>
            <div className="MainPage-body-content">
                <h1> Выберите сервисную компанию <br/>
                    для решения актуальной задачи </h1>
            </div>
            <div><ITSupport modalHandler={()=>continueModalHandler("itsupport")} clientAccess={clientAccess} id ={id}  trialInfo={trialInfo} supportID = {itsupportID}/></div>
            <div><AVITIM clientAccess={clientAccess} id = {id} trialInfo={trialInfo}/></div>
            <div><INVISION modalHandler={()=>continueModalHandler("invision")} clientAccess={clientAccess} id = {id } trialInfo={trialInfo}/></div>
            <div><Trials modalHandler={modalHandler} clientAccess={clientAccess} trialInfo={trialInfo} /></div>
            <div><FooterInfo /></div>
        </div>
    )
    const modal = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={infoModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Инструкция про механику бесплатной
                            заявки</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Есть возможность получить по 1 консультации во все сервисы на которые нет подписки. Менеджер определит сложность вашего запроса и поставит его в работу. </p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={modalHandler}>Понятно</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const continueWorkModal = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={continueModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Бесплатные консультации закончились</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Если вы хотите продолжить пользоваться услугами этого сервиса, оставьте заявку и наш менеджер свяжется с вами в ближайшее время.</p>
                    </div>
                    <div className='ModalWindow__Info_Item continue_buttons'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={closeModals}>Назад</button>
                        <button className='ModalWindow__Info_Item__Btn new_request' onClick={async ()=> await postApplicationToContinue()}>Оставить заявку</button>
                    </div>
                </div>
            </div>
        </div>
    );

    const confirmModalBlock = (
        <div>
            <div className='ModalWindow__Trial__Click_Background' style={confirmModal ? openStyle : null}>
                <div className='ModalWindow__Trial_Info'>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text Bold__Text'>Ваш запрос принят</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <p className='ModalWindow__Info_Item__Text'>Наш менеджер свяжется с вами в ближайшее время.</p>
                    </div>
                    <div className='ModalWindow__Info_Item'>
                        <button className='ModalWindow__Info_Item__Btn' onClick={closeModals}>На главную</button>
                    </div>
                </div>
            </div>
        </div>
    )

    
    return (
        <>
            {center}
            {infoModal ? modal : null}
            {continueModal ? continueWorkModal : null}
            {confirmModal ? confirmModalBlock : null}
        </>
    );
}

export default WithLoader(MainPage, axios);