import React from 'react';
import RedButton from "../../ApplicationPageComponents/RedButton/RedButton";
import './EmployeeMenu.css';

const EmployeeMenu = (props) => {
    return (
        <div className="EmployeeMenu">
            <h3 className="EmployeeMenu-title">{props.title}</h3>
            <RedButton
                name={props.buttonName}
                clicked={props.clicked}
            />
        </div>
    )
}

export default EmployeeMenu;