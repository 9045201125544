import './VideoForm.css';

export const VideoForm = (props) => {
    return (
        <div className="VideoForm">
            <div className="VideoForm-body">
                <div className="conferenceVideo">
                    <video controls="controls"></video>
                    <div className="reference">
                        <p className="reference-content">Скопируйте ссылку чтобы другие могли присоединиться к этой видеоконференции</p>
                        <div className="refInput">
                            <input type = "text" className="refInput-input" disabled placeholder="https://conference.name.room#12345"/>
                            <button className="copyLinkBtn" onClick={props.copy}>Скопировать ссылку</button>
                        </div>
                    </div>
                </div>
                <div className="comment">
                    <div className="title">
                        <p>Чат</p>
                        <div></div>
                    </div>
                    <div className="comments">
                        <div className="nameAndDate">
                            <p>Имя</p>
                            <p>12:23</p>
                        </div>
                        <div className="content">
                            <p>Замечтаельный чат, всем чатам чат! Очень рад на это посмотреть.</p>
                        </div>
                    </div>
                    <div className="message">
                        <input type="text" placeholder="Введите ваше сообщение" className="putCommentInput"/>
                        <button className="commentSend"><div></div></button>
                    </div>
                </div>
            </div>
            <div className="VideoForm-bottom">
                {props.bottom}
            </div>
        </div>
    )
}