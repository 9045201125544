import WithLoader from "../../hoc/WithLoader/WithLoader";
import axios from "../../axiosApi";
import {CHeaderForm} from "../../Components/ConferencePageComponents/ConferencePage/HeaderForm/CHeaderForm";
import {VideoForm} from "../../Components/ConferencePageComponents/ConferencePage/VideoForm/VideoForm";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import {FooterInfo} from "../../constants/Contact/FooterInfo/FooterInfo";
import {ITSGContact} from "../../constants/Contact/ITSGContact/ITSGContact";
import {clearMyInterval} from "../../Store/ApplicationsReducer/applicationsActions";
import {useEffect} from "react";


const ConferencePage = (props) => {
    const dispatch = useDispatch();

    const hash = useSelector(state => state.applications.data.hash)
    const clientId = useSelector(state => state.applications.data.clientId)


    useEffect(() => {
        dispatch(clearMyInterval())
        if (clientId && !hash) return dispatch(push(`/login/${clientId}`))
        if (!hash) return dispatch(push('/login'));
    }, [dispatch]);

    const showProfile = () => {
        dispatch(push("/profile"));
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.link);
    }

    const bottom = (
        <>
            <FooterInfo/>
            <ITSGContact/>
        </>
    )

    const body = (
        <div>
            <CHeaderForm showProfile = {showProfile}/>
            <VideoForm copy = {copyToClipboard} bottom = {bottom}/>
        </div>
    )

    return (
        body
    )
}

export default WithLoader(ConferencePage, axios);