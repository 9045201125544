import {useDispatch} from 'react-redux';
import {useEffect} from "react";
import './RefreshOrCreateForm.css';

const RefreshOrCreateForm = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
    })

    let instruments = (
        <>
            <input type="radio" className="instrumentsRadio-first" value="Старое оборудование" name="instrument"
                   onChange={props.CORSelectChange} onClick={props.leaveMouse}/> <p
            className="instrumentsRadio-text-first">Старое оборудование</p>
            <input type="radio" className="instrumentsRadio-second" value="Новое оборудование" name='instrument'
                   onChange={props.CORSelectChange} onClick={props.leaveMouse}/><p
            className="instrumentsRadio-text-second">Новое оборудование</p>
        </>
    )
    let users = (
        <>
            <input type="radio" className="usersRadio-first" value="Старый пользователь" name="user"
                   onChange={props.CORSelectChange} onClick={props.leaveMouse}/> <p
            className="usersRadio-text-first">Старый пользователь</p>
            <input type="radio" className="usersRadio-second" value="Новый пользователь" name='user'
                   onChange={props.CORSelectChange} onClick={props.leaveMouse}/><p
            className="usersRadio-text-second">Новый пользователь</p>
        </>
    )

    let locationOfOldUser = (
        <>
            <input type="radio" value="Больше не работает в компании" className="locationUser-first" onChange={props.CORSelectChange}
                   onClick={props.leaveMouse} name={props.CORSelectLocationOldUserName}/> <p
            className="locationUser-first-text">Больше не работает в компании</p>
            <input type="radio" value="Переведен на новое рабочее место" className="locationUser-second" onChange={props.CORSelectChange}
                   onClick={props.leaveMouse} name={props.CORSelectLocationOldUserName}/> <p
            className="locationUser-second-text">Переведен на новое рабочее
            место</p>
        </>
    )

    return (
        <div className="RefreshOrCreateForm">
            <p className="RefreshOrCreateForm-header-first">
                Присвоить ID на оборудование
            </p>
            <div className="RefreshOrCreateForm-header-second">
                <h1 className="header">
                    Обновить/Создать ID
                </h1>
                <div className="RefreshOrCreateForm-modals">
                    <h1 className="header"><span className="modalIcon" onMouseEnter={props.enterMouseQuestion}
                                                 onMouseLeave={props.leaveMouseQuestion}>?</span></h1>
                    <p className="hiddenModalText"
                       style={props.show ? {"display": "block"} : {"display": "none"}}>ID присваевается оборудованию,
                        <br/> а не сотруднику</p>
                </div>
            </div>
            <div className="RefreshOrCreateForm-selects">
                <div>
                    <select onClick={props.enterMouse} className="RefreshOrCreateForm_instruments-select"
                            name={props.CORSelectInstrName} style={{"color" : props.checked["instrument"] ? "black" : "#c0c0c0"}}>
                        <option
                            hidden="true">{props.checked["instrument"] ? props.checked["instrument"] : "Оборудование"}</option>
                    </select>
                    <div className="hiddenOptionBackground"
                         style={props.isSelected === "instrument" ? {"display": "block"} : {"display": "none"}}>
                        {instruments}
                    </div>
                </div>
                <div>
                    <select onClick={props.enterMouse} className="RefreshOrCreateForm_instruments-select"
                            name={props.CORSelectUsersName} style={{"color" : props.checked["user"] ? "black" : "#c0c0c0"}}>
                        <option
                            hidden={true}>{props.checked["user"] ? props.checked["user"] : "Пользователь"}</option>
                    </select>
                    <div className="hiddenOptionBackground"
                         style={props.isSelected === "user" ? {"display": "block"} : {"display": "none"}}>
                        {users}
                    </div>
                </div>
            </div>
            <div style={props.isOldUser ? {"display": "block"} : {"display": "none"}} className="hiddenParameters">
                <input type="text" className="oldUserInput" onChange={props.previousEmployee}
                       name={props.prevEmployeeName} placeholder="ФИО предыдущего пользователя"/>
                <select onClick={props.enterMouse} className="hiddenParameters-select"
                        name={props.CORSelectLocationOldUserName}>
                    <option hidden={true}>{props.checked["locationOldUser"] ? props.checked["locationOldUser"] : "Где предыдущий пользователь?"}</option>
                </select>
                <div className="hiddenOptionBackground-locationUser"
                     style={props.isSelected === "locationOldUser" ? {"display": "block"} : {"display": "none"}}>
                    {locationOfOldUser}
                </div>
                <div className="RefreshOrCreateForm-returnAccess">
                    <input type="radio" value="returnAccess" name={props.returnAccessName} className="returnAccess"
                           onChange={props.returnAccessChange}/> <span
                    className="returnAccess-radioButton"
                    style={props.checked["returnAccessOldUser"] ? {"padding": "2px 2px"} : {"padding": "10px 10px"}}><span
                    className="hiddenSelectButton"
                    style={props.checked["returnAccessOldUser"] ? {"display": "block"} : {"display": "none"}}> </span></span>
                    <p className="RefreshOrCreateForm-returnAccess-text">Отозвать доступы у предыдущего пользователя</p>
                </div>
            </div>
        </div>
    )
}

export default RefreshOrCreateForm;