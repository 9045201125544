import './FooterInfo.css';

export const FooterInfo = () => {
    return (
        <div className="FooterInfo">
            <p className="mobileWrapText">Единый номер</p>
            <h3 className="telephone">+7 727 357 30 68</h3>
            <p className="support">служба поддержки пользователей</p>
            <div className="socialIcons">
                <div className="social-instagram"></div>
                <div className="social-meta"></div>
                <div className="social-linkedin"></div>
                <div className="social-youtube"></div>
            </div>
        </div>
    )
}