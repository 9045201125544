import './PPHeaderForm.css';

export const PPHeaderForm = () => {
    return (
        <div className="PPHeaderForm">
            <div className="title">
                <h1 className="personalInfo">Личная комната <strong>a.khalov</strong> </h1>
                <p className="summation">10 трансляций  |  2 воспроизведения</p>
            </div>
            <div className="link">
                <p className="linkInput-content">Скопируйте ссылку чтобы пригласить</p>
                <div className="linkInput">
                    <input type = "text" className="linkInput-input" placeholder="https://conference.name.room#12345"/>
                    <button className="copyLinkBtn">Скопировать ссылку</button>
                </div>
            </div>
        </div>
    )
}
