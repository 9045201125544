import './ITSupport.css';

export const ITSupport = (props) => {
    return (
        <div style={props.clientAccess?.itsupportClient ? {"backgroundColor": "#fff", "zIndex": "4", "opacity": "100%"} : {"backgroundColor": "#e0e0e0", "borderRadius": "8px"}}>
        <div className="ITSupport" >
            <div className="ITSupport-logo"/>
            <div className="classification">
                <p>Орг-техника</p>
                <p>Компьютеры</p>
                <p>Локальная сеть</p>
                <p>Серверы</p>
            </div>
        </div>
        {props.clientAccess?.itsupportClient?  
            <div className="fill-itsg" onClick={()=>{window.location.assign(`https://test.asista.kz/application/${props.supportID}/newticket`)}}>
                <div className="invHoverLogo-itsg"/>
            </div>
            :props.trialInfo?.itsupportTrial ?
            <div className="fill-itsg">
                <p className='probe-text'>Консультации закончились <br/>Продолжить работу?</p>
            </div>    
            :
            <div className="fill-itsg"  onClick={()=>{window.location.assign(`https://test.asista.kz/${props.id}/trial/newticket`)}}>
                <p className='probe-text'>Получить бесплатную<br/> консультацию в IT-Support</p>
            </div>     
        }
    </div>
    )
}