import './ApplicationNotFound.css';

export const ApplicationNotFoundTop = () => {
    return (
        <div className="ApplicationNotFoundTop">
            <h3 className="ApplicationNotFoundTop-title">ID недействителен или неверен</h3>
        </div>
    )
}

export const ApplicationNotFoundCenter = () => {
    return (
        <div>
            <div></div>
            <p></p>
            <p></p>
        </div>
    )
}